<template>
  <div class="add-course-exam">
    <table-list
      title="设置考试"
      :loading="loading"
      :data="courseList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </table-list>
  </div>
</template>
<script>
// 搜索框配置
const searchForm = () => [
  {
    label: '考试ID',
    prop: 'examId',
  },
  {
    label: '考试名称',
    prop: 'examTitle',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'examId',
    label: '考试ID',
    minWidth: 80,
  },
  {
    prop: 'examTitle',
    label: '考试名称',
    minWidth: 200,
  },
  {
    prop: 'paperType',
    label: '考试方式',
    minWidth: 80,
    formatter: row => {
      return ['随机试卷', '自定义试卷'][row.paperType]
    },
  },
  {
    prop: 'questionTotal',
    minWidth: 100,
    sortable: true,
    align: 'right',
    label: '试题数',
  },
  {
    prop: 'scoreTotal',
    minWidth: 80,
    sortable: true,
    align: 'right',
    label: '总分',
  },
  {
    prop: 'createTime',
    minWidth: 150,
    label: '创建时间',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '选择考试',
    isShow: row => !row.assignFlag,
    method: _this.handleSelect,
  },
]
import TableList from '@/components/TableList'
import { classCourseExamList, classCourseExamUpdate } from '@/api/class'
import to from 'await-to'
export default {
  name: 'AddCourseExam',
  components: {
    TableList,
  },
  data() {
    return {
      searchForm,
      columns,
      operates,
      loading: false,
      courseList: [],
      selectCourse: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getExamList()
  },
  methods: {
    async getExamList() {
      if (this.loading) return
      const { current, size } = this.pager
      const { classCourseId } = this.$route.params
      this.loading = true
      const [res, err] = await to(
        classCourseExamList({
          classCourseId,
          current,
          size,
          ...this.queryParams,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.courseList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getExamList()
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getExamList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getExamList()
    },
    async handleSelect({ examId }) {
      const { classCourseId } = this.$route.params
      const [, err] = await to(classCourseExamUpdate({ classCourseId, examList: [examId] }))
      if (err) return this.$message.warning(err.msg)
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
.add-course-exam {
  ::v-deep.table-list {
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      display: none;
    }
  }
  .subTitle {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #4d4e5d;
    margin-left: 40px;
  }
}
</style>
